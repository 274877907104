@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@layer base{
hr{

@apply border-[#DEDFE1] border-[1px] border-solid

}
StarIcon{
@apply  bg-red
}
    
}

body{
    font-family:Poppins;
}
.star{
color: #FBB040;
}
.arrow{
    background: #004D93;
    color:white;
 
    border-radius:3px;

}

.swiper-pagination .swiper-pagination-bullet {
    /* opacity: 0.5; */
    background-color: black;}

    .swiper-pagination .swiper-pagination-bullet-active {
        background-color:  #FBB040;}